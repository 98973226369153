/* eslint-disable no-undef */
import jwtDecode from "jwt-decode"

export default function UserToken(){
    const { easysch_token } = localStorage
    const token = jwtDecode(easysch_token)
    return {
        full_name: token.full_name,
        groups: token.groups,
        role: token.role,
        slug: token.school_slug,
        schoolId: token.school_uid,
        userId: token.user_id,
        userUid: token.user_uid
    }
}