import React from 'react';

export default function Pagination({page, next, prev, pagination, count, offset, isPreviousData}) {
  return (
    <nav
    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
    aria-label="Pagination"
  >
    <div className="hidden sm:block">
      <p className="text-sm text-gray-700">
        Showing <span className="font-medium">{offset+1}</span> to <span className="font-medium">{10*page > count ? count : 10*page}</span> of{' '}
        <span className="font-medium">{count}</span> results
      </p>
    </div>
    <div className="flex-1 flex justify-between sm:justify-end">
      <button
      disabled={page === 1}
      onClick={prev}
        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-40"
      >
        Previous
      </button>
      <button
      onClick={next}
        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-40"
        disabled={isPreviousData || !pagination?.next}
      >
        Next
      </button>
    </div>
  </nav>
  )
}
