import Title from "./Title";
import Table from "./Table";
import Pagination from "components/Pagination";
import { Ripple } from "react-load-animations";

export default function FeeManagement({
  state,
  handleChange,
  handleSelect,
  handleSubmit,
  handleSearch,
  setState,
  history,
  students,
  open,
  setOpen,
  bill,
  isFetching,
  status,
  offset,
  pagination,
  next,
  prev,
  page,
  count,
  isPreviousData,
}) {
  return (
    <>
      <Title
        handleSearch={handleSearch}
        handleSubmit={handleSubmit}
        state={state}
        handleChange={handleChange}
        setState={setState}
        handleSelect={handleSelect}
        students={students}
        open={open}
        setOpen={setOpen}
        bill={bill}
      />
      {!isFetching && status === "success" && (
        <>
          <Table history={history} />
          <Pagination
            prev={prev}
            next={next}
            page={page}
            pagination={pagination}
            count={count}
            offset={offset}
            isPreviousData={isPreviousData}
          />
        </>
      )}
      {(status === "loading" || isFetching) && (
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <Ripple width={100} height={100} />
        </div>
      )}
    </>
  );
}
