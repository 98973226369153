import React from "react";
import { useQuery } from "react-query";
import { getRequest } from "api/apiCall";
import { BURSARCLASSSTUDENTS } from "api/apiUrl";
import { queryKeys } from "api/queryKey";
import BursarLayout from "components/BursarLayout";
import Table from "Bursar/Classes/Table";
import Cards from "Bursar/Classes/Cards";
import { Ripple } from "react-load-animations";
import UserToken from "components/Token";
import { Link, useParams } from "react-router-dom";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { ExternalLinkIcon } from "@heroicons/react/outline";

export default function SingleClass() {
  const params: { class: string } = useParams();
  const [token, setToken] = React.useState(UserToken());
  React.useEffect(() => {
    setToken(UserToken());
  }, []);
  console.log(token);
  const { schoolSlug: school } = localStorage;

  const {
    data: studentList,
    status,
    isFetching,
  } = useQuery(
    [queryKeys.getClassStudents, token?.schoolId, params?.class],
    async () =>
      await getRequest({
        url: BURSARCLASSSTUDENTS(token?.schoolId, params?.class),
      }),
    {
      retry: 2,
      enabled: !!(token?.schoolId && params?.class),
      keepPreviousData: true,
    }
  );
  const [students, setStudents] = React.useState(studentList?.data);
  React.useEffect(() => {
    setStudents(studentList?.data);
  }),
    [studentList?.data];
  const [open, setOpen] = React.useState(false);
  return (
    <BursarLayout currentPage="Classes">
      {!isFetching && status === "success" && (
        <>
          <Table
            students={students}
            school={school}
            open={open}
            setOpen={setOpen}
          />
          <div className="sm:hidden">
            <div className="grid grid-cols-1 gap-2">
              {students?.map((student, i) => (
                <div className="gap-2 bg-white rounded-lg shadow p-6 grid grid-cols-6">
                  <div className="col-span-1 flex items-center">{i+1}.</div>
                  <div className="col-span-3">
                  <div className="flex items-center gap-2">
                  <img className="w-8 h-8 object-center object-cover rounded-full" src={student.image ? student.image : student.gender === "Male" ? "https://res.cloudinary.com/jewbreel1/image/upload/v1625737172/jewbreel/sms/male_avatar_c3v0vu.png" : "https://res.cloudinary.com/jewbreel1/image/upload/v1625737170/jewbreel/sms/female_avatar_pgqx9s.png"} alt="" />
                <h3 className="text-gray-900 text-sm font-medium">{student.last_name} {student.first_name} {student.middle_name}</h3>
                
              </div>
                  </div>
                  <div className="col-span-2 flex items-center">
                  <Link
                to={`/student-${params.class}-${student.id}`}
                className="flex items-center justify-center text-sm text-gray-700 font-medium hover:text-gray-500"
                >
                  <ExternalLinkIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">View</span>
                </Link>
                  </div>
                </div>
              ))}
            </div>
            {/* <Cards
              students={students}
              school={school}
              open={open}
              setOpen={setOpen}
            /> */}
          </div>
        </>
      )}
      {(status === "loading" || isFetching) && (
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <Ripple width={100} height={100} />
        </div>
      )}
    </BursarLayout>
  );
}
