import { getRequest, postRequest } from 'api/apiCall'
import { ADDDISCOUNT, ADDOUTSTANDING, GETSTUDENTBILL, PAYMENTS, STUDENT } from 'api/apiUrl'
import { queryKeys } from 'api/queryKey'
import BursarLayout from 'components/BursarLayout'
import UserToken from 'components/Token'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { BsArrowLeftCircleFill } from "react-icons/bs"
import { Link } from 'react-router-dom'
import { ToastContext } from 'App'
import { TbCircle1, TbRipple } from 'react-icons/tb'
import { FaCircle } from 'react-icons/fa'


export default function BursarForm() {
    const params: {id: any, class: any} = useParams()
    const [token, setToken] = React.useState(UserToken())
    const history = useHistory()
  React.useEffect(()=>{
    setToken(UserToken())
  },[])
  const { data: studentList } = useQuery(
    [queryKeys.getStudent, token?.schoolId],
    async () => await getRequest({ url: STUDENT(token?.schoolId, params?.id) }),
    {
      retry: 2,
      enabled: !!token?.schoolId,
    }
  );
  const [list, setStudent] = React.useState(studentList?.data);
    React.useEffect(() => {

    setStudent(studentList?.data);
  }, [studentList?.data]);
  console.log(list)
  const { data: studentBillList } = useQuery(
    [queryKeys.getStudentBill, token?.schoolId, params?.id],
    async () => await getRequest({ url: GETSTUDENTBILL(token?.schoolId, params?.id) }),
    {
      retry: 2,
      enabled: !!token?.schoolId && !!params?.id
    }
  );
  const [bill, setBill] = React.useState(studentBillList?.data)
  console.log(bill)
  React.useEffect(()=>{
    setBill(studentBillList?.data)
  },[studentBillList?.data]);
  const [bills, setBills] = React.useState(
    bill?.map(fee=>{
      return{
        display_name: fee.bill.display_name,
        name: fee.bill.name,
      unit: fee.bill.unit,
      amount: fee.amount,
      amount_paid: fee.amount_paid,
      amount_left: Number(Number(fee.bill.unit)*Number(fee.amount)) - Number(fee.amount_paid),
      amount_paying: 0,
      id: fee.id
      }
    })
  )
  const [paymentDisabled, setPaymentDisabled] = React.useState(false)
  React.useEffect(()=>{
    setBills(
      bill?.map(fee=>{
        return{
          display_name: fee.bill.display_name,
          name: fee.bill.name,
        unit: fee.bill.unit,
        amount: fee.amount,
        amount_paid: fee.amount_paid,
        amount_left: Number(Number(fee.bill.unit)*Number(fee.amount)) - Number(fee.amount_paid),
        amount_paying: 0,
        id: fee.id
        }
      })
    )
  },[bill])
  console.log(bills)
  const [errors, setErrors] = React.useState([])
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBills(bills.map(fee=>{
      return{
        ...fee,
        amount_paying: fee.name===e.target.name ? e.target.value : fee.amount_paying
      }
    }))
  }
  const [source, setSource] = React.useState("")
  const handleSource = (e:React.ChangeEvent<HTMLSelectElement>)=>{
    setSource(e.target.value)
  }
  const cache = useQueryClient();
  const { showAlert } = React.useContext(ToastContext);
  const { mutate } = useMutation(postRequest, {
    onSuccess(data) {
      showAlert({
        message: data?.message,
        severity: "success",
      });
      setPaymentDisabled(false)
      // history.push(`/class-${params.class}`, `/class-${params.class}`)
      cache.invalidateQueries();
    },
  });
  const handleDiscount = (e: any) => {
    e.preventDefault();
    mutate({
      url: ADDDISCOUNT(token?.schoolId, params.id),
      
      data: {
        amount: discount,
        student_id: params.id,
        bill_type: "school_fee",
      }
    })
  }
  const handleDebt = (e: any) => {
    e.preventDefault();
    mutate({
      url: ADDOUTSTANDING(token?.schoolId, params.id),
      
      data: {
        amount: debt,
        student_id: params.id,
        bill_type: "debt",
      }
    })
  }
  const handleSubmit =(e:any)=>{
    e.preventDefault()
    setPaymentDisabled(true)
    if(source!==("cash"||"transfer")) setErrors([...errors, "Please Select Either Cash Or Transfer"]);
    const returnedBills = bills.map(fee=>{
      return{
        student_bill_id: fee.id,
        amount: fee.amount_paying,
        source
      }
    })
    console.log(returnedBills)
    mutate({
      url: PAYMENTS(token?.schoolId),
      data: {
        student_id: params.id,
        payments: returnedBills
      }
    })
  }
  const [discount, setDiscount] = React.useState(null)
  const [debt, setDebt] = React.useState(null)
  return (
    <BursarLayout currentPage={"Classes"}>
<div className="min-h-screen max-w-5xl mx-auto">
<Link to={`/class-${params.class}`} className="my-4 flex cursor-pointer">
        <BsArrowLeftCircleFill className="w-6 h-6 mr-2" />
        <span className="font-extrabold">Back To Class</span>
      </Link>
      <div className="flex flex-col items-center justify-center w-full h-4/5">
        <div className="grid w-full grid-cols-1 gap-4">
        <div className="p-6 bg-white">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:space-x-5">
            <div className="flex-shrink-0">
              <img className="object-cover object-center w-20 h-20 mx-auto rounded-full" src={list?.image ? list?.image : list?.gender === "Male"
                                  ? "https://res.cloudinary.com/jewbreel1/image/upload/v1625737172/jewbreel/sms/male_avatar_c3v0vu.png"
                                  : "https://res.cloudinary.com/jewbreel1/image/upload/v1625737170/jewbreel/sms/female_avatar_pgqx9s.png"} alt="" />
            </div>
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-xl font-bold text-gray-900 sm:text-2xl">{list?.full_name}</p>
            </div>
          </div>
        </div>
      </div>
          <div className="overflow-hidden bg-white rounded-lg shadow">
            <h2
              className="p-5 text-2xl font-extrabold"
              id="profile-overview-title"
            >
              Add Outstanding Debt and Discount
            </h2>
            <div className="p-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-4">
                {
                  [
                    {id: "discount", value: discount, placeholder: `Enter Discount For ${list?.full_name}`, onSubmit: handleDiscount, onChange: setDiscount},
                    {id: "debt", value: debt, placeholder: `Enter Oustanding Debt For ${list?.full_name}`, onSubmit: handleDebt, onChange: setDebt},
                  ].map((field,i)=>(
            <form className="" onSubmit={field.onSubmit} key={i}>
                <label
                  htmlFor={field.id}
                  className="block text-sm font-medium text-gray-700"
                  >
                  {field.placeholder}
                </label>
                <div className="mt-1">
                  <input
                    onChange={(e:React.ChangeEvent<HTMLInputElement>)=>field.onChange(e.target.value)}
                    type="number"
                    name={field.id}
                    value={field.value}
                    id={field.id}
                    placeholder={field.placeholder}
                    autoComplete={field.id}
                    className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                    />
                </div>
                <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Save
            </button>
          </div>
        </div>
              </form>
                  ))
                }
                    </div>
                    <h2
              className="p-5 text-2xl font-extrabold"
              id="profile-overview-title"
            >
              Make Payment
            </h2>
           <form
        className="space-y-8 divide-y divide-gray-200"
        id="AddEditResult"
        onSubmit={handleSubmit}
      >
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            {/* <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
            <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p>
          </div> */}

<div className="my-2">
              <label htmlFor="source">Please Select Either Cash Or Transfer</label>
              <select
              value={source}
                required
                name="source"
                id="source"
                onChange={handleSource}
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
              >
                <option value="">Please Select Payment Type</option>
                <option value="cash">Cash</option>
                <option value="transfer">Transfer</option>
              </select>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-4">
              {
                bills?.map((fee,i)=>(
              <div className="" key={i}>
                <label
                  htmlFor={fee.name}
                  className="block text-sm font-medium text-gray-700"
                >
                  {fee.display_name} {" "}
                  <span className="text-sm ml-2">Amount Left: #{fee.amount_left}</span>
                </label>
                <div className="mt-1">
                  <input
                    onChange={handleChange}
                    type="number"
                    max={fee.amount_left}
                    name={fee.name}
                    value={fee?.amount_paying}
                    id={fee.name}
                    placeholder={`Enter ${fee.display_name} Amount`}
                    autoComplete={fee.name}
                    className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                  />
                </div>
              </div>
                ))
              }
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={paymentDisabled}
              className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:animate-pulse"
            >
              {
                !paymentDisabled ?
                "Save"
                :
                <>
              <FaCircle className="mr-2" />
              Loading .....
              </>
              }
            </button>
          </div>
        </div>
      </form> 
        </div>
          </div>
        </div>
      </div>
    </div>
    </BursarLayout>
  )
}
