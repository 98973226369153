import React from "react";
import { useQuery } from "react-query";
import { getRequest } from "api/apiCall";
import { BURSARCLASSSTUDENTS, HOMEROOMS } from "api/apiUrl";
import { queryKeys } from "api/queryKey";
import jwtDecode from "jwt-decode";
import BursarLayout from "components/BursarLayout";
import DropDown from "components/DropDown";
import Table from "Bursar/Classes/Table";
import Cards from "Bursar/Classes/Cards";
import { Ripple } from 'react-load-animations';
import UserToken from "components/Token";
import { Link } from "react-router-dom";

export default function AllClasses() {
  const [token, setToken] = React.useState(UserToken())
  React.useEffect(()=>{
    setToken(UserToken())
  },[])
  console.log(token)
  const easysch_token: { school_uid: any } = jwtDecode(
    localStorage?.easysch_token
  );
  const { schoolSlug: school } = localStorage

  const { data: homerooms, isFetching, status } = useQuery(
    [queryKeys.getClasses, token?.schoolId],
    async () => await getRequest({ url: HOMEROOMS(token?.schoolId) }),
    {
      retry: 2,
      enabled: !!token?.schoolId,
    }
  );

  const [rooms, setRooms] = React.useState(homerooms?.data);
  const roomval = homerooms?.data;
  React.useEffect(() => {
    setRooms(roomval);
  }, [roomval]);
const [open, setOpen] = React.useState(false)
  return (
    <BursarLayout currentPage="Classes">
        {
           (!isFetching && status==="success") &&
           <>
            <div className="flex flex-wrap pt-5 -m-2" id="Classes">
      {rooms?.map((room: any) => (
        <Link
          to={`/class-${room.id}`}
          className="w-full p-2 lg:w-1/3 md:w-1/2 sm:w-full searchBody"
          key={room.id}
        >
          <>
            <div className="flex items-center h-full p-4 transform bg-gray-100 border border-gray-200 rounded-lg shadow cursor-pointer hover:scale-105 hover:bg-gray-900 hover:text-white duration-500 transition-all">
              <div
                className={`avatar avatar-lg text-white flex-shrink-0 rounded-full mr-4 bg-gray-700 p-3`}
              ></div>
              <div className="flex-grow">
                <h2 className="font-bold title-font">{room?.name}</h2>
              </div>
            </div>
          </>
        </Link>
      ))}
    </div>
           </>
        }
        {
        (status==="loading" || isFetching) &&
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <Ripple width={100} height={100} />
        </div>
      }
    </BursarLayout>
  );
}
