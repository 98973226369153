import React from "react";
import SchoolLayout from "components/SchoolLayout";
import Cards from "School/Classes";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getRequest, postRequest } from "api/apiCall";
import { HOMEROOMS } from "api/apiUrl";
import { queryKeys } from "api/queryKey";
import { ToastContext } from "App.jsx";
import jwtDecode from "jwt-decode";
import { Link, useParams } from "react-router-dom";
import { TbCurrencyNaira } from "react-icons/tb";

export default function Pin() {
  const easysch_token: { school_uid: any } = jwtDecode(
    localStorage?.easysch_token
  );
  const { schoolSlug: school } = localStorage;

  const { data: homerooms } = useQuery(
    [queryKeys.getClasses, easysch_token?.school_uid],
    async () => await getRequest({ url: HOMEROOMS(easysch_token?.school_uid) }),
    {
      retry: 2,
      enabled: !!easysch_token?.school_uid,
    }
  );
  const [rooms, setRooms] = React.useState(homerooms?.data);
  const roomval = homerooms?.data;
  React.useEffect(() => {
    setRooms(roomval);
  }, [roomval]);
  const [open, setOpen] = React.useState(false);
  return (
    <SchoolLayout currentPage="Pin">
      <div className="flex flex-wrap pt-5 -m-2" id="Classes">
        {rooms?.map((room: any) => (
          <Link
            to={`/pin-${room.id}`}
            target="_blank"
            className="w-full p-2 lg:w-1/3 md:w-1/2 sm:w-full searchBody"
            key={room.id}
          >
            <>
              <div className="flex items-center h-full p-4 transform bg-gray-100 border border-gray-200 rounded-lg shadow cursor-pointer hover:scale-105 hover:bg-gray-900 hover:text-white duration-500 transition-all">
                <div
                  className={`avatar avatar-lg text-white flex-shrink-0 rounded-full mr-4 bg-gray-700 p-3`}
                ></div>
                <div className="flex-grow">
                  <h2 className="font-bold title-font">{room?.name}</h2>
                </div>
              </div>
            </>
          </Link>
        ))}
      </div>
    </SchoolLayout>
  );
}
