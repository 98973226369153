import SchoolFees from "pages/school/fees";
import { Route } from 'react-router-dom';
import { Switch } from "react-router-dom";
import ErrorPage from "pages/404";
import Dashboard from '../pages/bursar/index';
import Bill from "pages/bursar/bill";
import Debt from "pages/bursar/debt";
import Discount from "pages/bursar/discount";
import BursarLayout from "components/BursarLayout";
import SingleClass from "pages/bursar/class";
import BursarForm from "pages/bursar/form";
import AllClasses from "pages/bursar/classes";

export const Bursar = (
  <Switch>
    <Route path="/" component={Dashboard} exact />
    <Route path="/class-:class" component={SingleClass} exact />
    <Route path="/classes" component={AllClasses} exact />
    <Route path="/fees" component={SchoolFees} exact />
    <Route path="/bill" component={Bill} exact />
    <Route path="/debt" exact >
      <BursarLayout currentPage="Debt">
        <Debt />
      </BursarLayout>
    </Route>
    <Route path="/discount" exact >
      <BursarLayout currentPage="Discount">
        <Discount />
      </BursarLayout>
    </Route>
    <Route path="/student-:class-:id" component={BursarForm} exact />
    <Route
      // path="*"
      // component={ErrorPage}
      render={(props)=>(
        <ErrorPage {...props} />
      )}
    />
  </Switch>
);