import React from "react";
import AssignedCourse from "./AssignedCourse";
import Header from "./Header";

export default function Dashboard({ teacher, courses, school, status }) {
  return (
    <>
      <Header teacher={teacher} />
      {
        status==="success" && 
      <AssignedCourse courses={courses} school={school} />
      }
    </>
  );
}
